//= require jquery 
require('jquery')
import $ from 'jquery';

(function() {
	/**************************  $Send Forms  ******************************/
	window.addEventListener("DOMContentLoaded", () => {
		var $form = $('form');
		$form.on( 'submit' , function(e){
			if ( $(this).data('ajax') == 1 ) {
				e.preventDefault();
				sendForm( $(this) );
			}
		})

		function sendData(url, method, data, $form, callback){
			var $btn = $form.find('.text'),
				$response = $form.find('.form-response');

			$.ajax({
				beforeSend: function(objeto){
					$response.html('');
					$btn.html("Loading..");
				},
				complete: function(objeto, exito){ $btn.html("Signed up, thanks!"); },
				data: data,
				success: function(dat){  callback(dat, $response); },
				type: method,
				url: url,
			});
		}

		function sendForm($form){
			var fieldsData = getFieldsData($form),
				url = $form.attr('action'),
				method = $form.attr('method');

			sendData(url, method, fieldsData, $form, showResults)
		}

		function showResults(data, $response){
			$response.html(data);
			$response.find('.alert').slideDown('slow');
			$('#news-email').val("");
		}

		function getFieldsData($form) {
			var $fields = $form.find('input, button, textarea, select'),
				fieldsData = {};

			$fields.each( function(){
				var name = $(this).attr('name'),
					val  = $(this).val(),
					type = $(this).attr('type');

				if ( typeof name !== 'undefined' ){

					if 	( type == 'checkbox' || type == 'radio' ){

						if ( $(this).is(':checked') ){
							fieldsData[name] = val;
						}
					} else {
						fieldsData[name] = val;
					}

				}
			});

			return fieldsData
		}

	});

}).call(this);